import * as React from "react"

const Columns = ({className = "", children}) => {
  return (
    <div className={`columns ${className}`}>
      {children}
    </div>
  )
}

const Col = ({isVCentered = false, isSpaced = false, isSpacedEven = false, className = "", style, children}) => {
  return (
    <div className={`
      column p-3
      ${isVCentered ? "is-flex is-flex-direction-column is-justify-content-center " : "" }
      ${isSpaced ? "is-flex is-flex-direction-column is-justify-content-space-between " : "" }
      ${isSpacedEven ? "is-flex is-flex-direction-column is-justify-content-space-evenly " : "" }
      ${className}
      `} style={style}>
      {children}
    </div>
  )
}


export {Columns, Col}
