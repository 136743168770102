import React from "react"

import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

export const MainLogo = () => {
  return (
    <StaticImage loading="eager" backgroundColor="#a54e2e" src="../images/logo_main.png" alt="LA LICORNE - CALVI PLAGE" style={{maxHeight: "50vh", width: "100%"}} objectFit="contain"/>
  )
}

export const NavLogo = () => {
  return (
    <StaticImage loading="eager" backgroundColor="#a54e2e" src="../images/logo_banner.png" height={200} alt="LA LICORNE - CALVI PLAGE" />
  )
}
