import * as React from "react"
import { Layout } from 'antd';
import {Columns, Col} from 'sections/columns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'

const AntFooter = Layout.Footer;

const Footer = () => {
  return (
    <footer className="footer has-background-primary p-5">
      <Columns>
      <Col className="is-offset-1 is-5">
        <div className="content has-text-left has-text-light">
        <h3 className="title is-3 has-text-light">La Licorne</h3>
        <p><FontAwesomeIcon icon={faLocationDot} /> <a href="https://goo.gl/maps/BbKaJZx3Ka321uYd9" className="link-underline">Route  de la plage, 20260 Calvi</a></p>
        <p><FontAwesomeIcon icon={faPhone} /> <a href="tel:+33495650862" className="link-underline">04 95 65 08 62</a></p>
        <p><FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:lalicornecalvi@gmail.com" className="link-underline">lalicornecalvi@gmail.com</a></p>
        </div>
      </Col>
      <Col className="is-5">
      <div className="content has-text-right has-text-centered-mobile has-text-light">
        <h3 className="has-text-light"><a href="https://instagram.com/lalicornecalvi"><FontAwesomeIcon icon={faInstagram} /> </a> &nbsp; <a href="https://facebook.com/lalicornecalvi"><FontAwesomeIcon icon={faFacebook} /> </a></h3>
        <p>L'ambiance décontracté d'une paillote de charme</p>
        <p>Restauration en continu tous les jours de 12h à 22h</p>
        <p>Plage ouverte de 9h30 à 19h</p>
      </div>
      </Col>
      </Columns>
      <Columns>
      <Col>
        <div className="content has-text-centered has-text-light">
          <p>La Licorne - 2023</p>
        </div>
      </Col>
      </Columns>
    </footer>
  )
}

export default Footer
